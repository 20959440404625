<script setup>
import { onMounted, ref } from 'vue';

const model = defineModel()
const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        class="sm:text-sm border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 focus:border-brand focus:ring focus:ring-brand focus:ring-opacity-50 rounded-md shadow-sm"
        v-model="model"
    >
</template>
